import React, { Component } from "react"
import axios from "axios"
import { Swiper, SwiperSlide } from "swiper/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const params = {
  slidesPerView: 6,
  spaceBetween: 0,
  grabCursor: true,
  breakpoints: {
    1920: {
      slidesPerView: 6,
    },
    1680: {
      slidesPerView: 5,
    },
    1000: {
      slidesPerView: 4,
    },
    768: {
      slidesPerView: 3,
    },
    320: {
      slidesPerView: 2,
    },
  },
}

class InstagramSlider extends Component {
  state = {
    loading: false,
    error: false,
    photos: [],
  }
  componentDidMount() {
    this.fetchInstagramPhotos()
  }

  render() {
    if (!this.state.loading) {
      const photos = this.state.photos
      const images = []

      photos.forEach((photo, i) => {
        images.push(
          <SwiperSlide>
            <a
              href={photo.url}
              target="_blank"
              rel="noreferrer noopener"
              key={i}
            >
              <div className={"instagram-overlay"}>
                <FontAwesomeIcon icon={["fab", "instagram"]} size="2x" />
              </div>
              <img
                src={photo.image}
                alt="@delcakes.in Instagram"
                loading="lazy"
              />
            </a>
          </SwiperSlide>
        )
      })

      return <Swiper {...params}>{images}</Swiper>
    } else {
      return <div>Loading</div>
    }
  }
  // This data is fetched at run time on the client.
  fetchInstagramPhotos = () => {
    this.setState({ loading: true })
    axios
      .get(`https://orders.delcakes.in/api/instagram_media`)
      .then(photos => {
        const fetchedImages = photos.data.data.map(x => {
          return { image: x.media_url, url: x.link }
        }).filter(x => !x.image.includes(".mp4"));

        this.setState({
          loading: false,
          photos: fetchedImages,
        })
      })
      .catch(error => {
        this.setState({ loading: false, error })
      })
  }
}

export default InstagramSlider
